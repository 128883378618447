import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { AppImage } from "@/components/AppImage";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { LinkButton } from "@/components/LinkButton";
import { BaseContainer } from "@/components/BaseContainer";
import { Routes } from "@/lib";
import TextUnderline from "@/components/TextUnderline";

function AppIntro() {
  return (
    <Box position={"relative"} bg={"gray.900"} mt={"45px"}>
      <Box
        position={"absolute"}
        top={0}
        w={{ base: "100%", lg: "43%" }}
        h={"100%"}
        zIndex={5}
        right={0}
      >
        <AppImage src={"/images/homeImg.jpg"} w={"100%"} h={"100%"} />
      </Box>
      <BaseContainer
        backgroundColor={{ base: "blackAlpha.900", xl: "transparent" }}
        zIndex={"10"}
        position={"relative"}
      >
        <Flex
          minHeight={"600px"}
          w={{ base: "100%", xl: "56%" }}
          h={"100%"}
          flexDirection={"column"}
          justifyContent={"center"}
          zIndex={"10"}
          position={"relative"}
          px={{ base: "24px", xl: "18px" }}
        >
          <Box maxWidth={"600px"} w={"100%"}>
            <FadeInAnimation>
              <Text
                fontSize={{ base: "3xl", md: "4xl" }}
                fontWeight="500"
                fontFamily={"heading"}
                color={"white"}
              >
                The{" "}
                <Text as={"span"} color={"brand100"}>
                  App
                </Text>{" "}
                Designed <br />
                for People Seeking{" "}
                <Text as={"span"} color={"brand100"}>
                  Help
                </Text>
                .
              </Text>
              <Text fontSize="lg" color={"brandGray"} py={"24px"}>
              Our platform focuses on the optimal well-being of individuals, 
              particularly those individuals experiencing anxiety and depressive 
              disorders. Through the use of cognitive behavioral therapy, individuals
               are guided, supported, and encouraged through recommender features on
               the platform to assist them in accessing therapists, building positive
                routines, and having a space to express themselves. 
              </Text>
              <LinkButton
                href={Routes.features}
                label={"Learn more"}
                color={"white"}
              />
            </FadeInAnimation>
          </Box>
        </Flex>
      </BaseContainer>
    </Box>
  );
}

export default AppIntro;
