import React from "react";
import PageHeader from "@/components/PageHeader";
import { BaseContainer } from "@/components/BaseContainer";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { Box, Text } from "@chakra-ui/react";
import FeatureSection from "@/screens/FeaturesScreen/components/FeatureSection";
import { Assets } from "@/lib";
import AppLayout from "@/layout/AppLayout";

export function FeaturesScreen(props) {
  return (
    <AppLayout>
      <PageHeader
        image={Assets.howItWorks}
        introHead={"The Idea Behind LITH"}
        introBody={
          "The idea behind LITH is to use a centralized technology system to curb depression in African University students."
        }
      />
      <BaseContainer px={["18px", "16px", "2%"]} py={"75px"}>
        <FadeInAnimation>
          <Text fontSize="4xl" fontFamily={"heading"} fontWeight={"500"}>
            Curbing depression in African University students
          </Text>
          <Text fontSize={"md"} mt="7" mb={"20px"}>
          LITH provides a centralized platform for managing an 
          individual’s experiences with mind health. 
          The platform is for students who may not have the time or access
           to a therapist to help navigate their emotions during critical times of need. 
        The system has an integrated recommender feature that provides suggestions on 
        behavioral changes, well-being activities, and positive messaging to help 
        improve the overall mental well-being of the user. 

          </Text>
          <Text fontSize={"md"} mt="7" mb={"20px"}>
          The app is equipped with licensed 
        therapists that are available for the students to chat, call, and video message within 
        critical times of need. Unlike any other mental health apps, LITH will also provide community 
        support groups moderated by our A.I systems to provide maximum support for the student’s mental health.
          The app is equipped with licensed 
        therapists that are available for the students to chat, call, and video message within 
        critical times of need. Unlike any other mental health apps, LITH will also provide community 
        support groups moderated by our A.I systems to provide maximum support for the student’s mental health.
          </Text>
        </FadeInAnimation>
      </BaseContainer>
      <FeatureSection />
    </AppLayout>
  );
}
