const reasearchData = [
  {
    title: `Note from the LITH Africa Team ❤️ `,
    name: `LITH Africa`,
    image: "/images/msglithteam.png",
    id: 1,
    date: "Sep 22, 2022 2 min read",
    desc: `These stories are to inspire hope and courage to those who read them. These are the voices of young Africans working towards optimal mind health and well being. However, please note that some of these stories can be triggering. Please read them with compassion and care...`,
    content: `Dear Reader,
    <br/>
    <br/>


    The following stories are carefully picked from a range of young African writers who are sharing their mental health journeys. The stories are meant to inspire and to spread the African narrative of how mental health looks like on our continent and how we deal with these issues as a people. Please read with passion and care and we would like to let you know that some of the stories might be triggering as well. We hope you find pieces of yourselves from some of these stories and you are reminded that you are not alone. Also, feel free to contact us if you have a story that you might want us to publish and that you feel you need to share with the world and the LITH family.

    <br/>
    <br/>

    With Love ❤️ ` ,
  },
  {
    title: `I Choose Life. I Choose Hope. A True Story 🐺 `,
    id: 2,
    name: `Tyrone Takawira`,
    image: "/images/ty.jpeg",
    date: "Sep 22, 2020 2 min read",
    desc: `I recall the softness of her voice, as she strung words together that I have carried with me till this day, eight years later, within the intricacies of my heart. I recall the softness of her voice....`,
    content: `The knife, locked in my hands, feels cold. I am reminded of my own life. The objective coldness of my circumstances. My reflection on the cutlery is blurry, perhaps an analogy of my current mental state. I lock the door, hands shaking in continuity. Hands shaking in uncertainty. I lock the door because I read somewhere, perhaps on Quora, that the first rule of suicide is isolation. I had no business searching for this, but I did. Unlike many suicides, however, I do not leave a sentimental note. I leave no flowery prose about my motives, my pain, my final words. I had no strength to be poetic. But mostly, I doubted anyone would care. 
    My first attempt is unsuccessful. The knife, meant to plunge into the centre of my stomach, merely slices the skin. I am left with trailing blood, and a scar for life. The second rule of suicide is to magnify your worthlessness. I did not read about this anywhere. I recall the myriad of voices in my head. The concert of my insecurities. I recall the feeling of abandonment. The judgement – about my family’s financial situation. The silhouettes of my childhood abuse. I was only seven. I recall how heavy my mind was – how my perception of reality was clouded by negativity. And the more these voices spoke, the more real they become, and the more suicide felt like the answer. 
    <br/>
    <br/>
    The knife, locked in my hands, still feels cold. I am reminded of my own life. The coldness of my existence. The permanence of pain. I take a deep breath. I do not offer a prayer. I grip the edge tighter; I ensure the tip is aligned with the centre of my stomach. This time, I won’t miss. And, right before I submerged the knife into my soul, into the very strand that holds me together, my phone rings. ‘Aunty’ – the screen glows. And, at the hearing of her first ‘My dear’, I fell into a stream of tears. I told her everything. The same way an open book shares its secrets. I told her about the voices in my head – that tormented me into depression. I told her about the friends I lost. I told her about our financial situation. I recall the softness of her voice, as she strung words together that I have carried with me till this day, eight years later, within the intricacies of my heart. I recall the softness of her voice – within the myriad of voices. She said that struggle builds character, and God had a bigger plan, working behind the scenes. She said that I mattered, and that I am not defined by my circumstances. Not defined. And, all of a sudden, the voices went quiet, and the knife in my hand felt too hot to hold. I remember my mind becoming clearer, filling with a newly found optimism. The same way sun rays fill a clouded sky. I remember choosing life. I remember choosing hope. 
    <br/>
    <br/>
    The third rule of suicide is … it cannot exist in a place of love. 
    
    `,
  },
  {
    title: `Growing Up 🐻`,
    name: `Mirabel Laryea`,
    image: "/images/sad5.jpeg",
    id: 3,
    date: "Sep 22, 2020 2 min read",
    desc: `I write this because I’m not sure I’ve recovered fully but hopefully I’ll adjust soon. I’m still learning how to be authentic and stay true to myself. But I’m still broken. Very broken...`,
    content: `I was living in a world where my steps were monitored and judged for no apparent reason. Growing up was kinda hard for me. I constantly felt guilty and had a hard time fitting in with anyone. I was a very cautious, shy, and low-confidence kid. I felt like I was never good enough like no matter how hard I tried, I just wasn’t like everyone else. I wasn’t interested in anything (still not). Later on, the increased emotions came. 
    <br/>
    <br/>
    I’d get so upset or so mad so quickly and without a reason. I didn’t realize I had depression till this year ( I’m 19). I never understood why all of the other children were so happy and carefree. Like what is there to be happy about? Everyone else seemed great at making friends. I felt an overwhelming sense of sadness even at a young age. I was constantly doubting myself and worrying about every little thing. I questioned my existence on a daily basis. I couldn't focus on anything, not even my grades. 
    <br/>
    <br/>
    I was worried about what others would say about me. Angel Haze once said, “It’s easy to keep pretending that there’s nothing wrong, but it’s harder to keep your head up and be strong.” I felt that. I was a very shy child and after some time, I decided to let myself go. I allowed myself to feel everything that I was going through, I was reacting to every little thing. I could feel my soul slowly drowning. It felt like I wasn’t human anymore. I knew I was too young to be feeling this way but it still ate me up anyway. Depression really doesn’t work with age.
    <br/>
    <br/>
    I grew up being verbally abused too. I saw myself as a disgrace to my family. I never did anything correctly or appeasing. I was pretty sure they were ashamed to have me. This is why I isolate myself from my family, my parents never appreciated any of my efforts, and even when I gave my best it still seemed like a miniature of theirs.
    I write this because I’m not sure I’ve recovered fully but hopefully I’ll adjust soon. I’m still learning how to be authentic and stay true to myself. But I’m still broken. Very broken.
    So now, I suck at showing and expressing my emotions to the point where I feel like it's affecting my mental health, and the people close to me. I don’t even know how to give hugs and I barely tell people I love them. Even now, I find it really hard to explain what I'm feeling. I am not really open with my feelings, I’ve built huge walls that prevent me from expressing any form of emotion. Sometimes I have a trashy attitude, it’s not my fault, I only do that when I’m madly in love with you. Sounds crazy right? but that’s only coz I don’t want to tell you how I truly feel about you.
    <br/>
    <br/>
    Look, I suck at showing love. Affection makes me very uncomfortable. I love you but I can’t tell you. Yes, I am very strange. Growing up, everyone else seemed great at making friends. I questioned my existence on a daily basis (and I still do). Everyone else seemed great at interacting (still looks that way to me). People have been saying that they can't read me and that makes it really hard to connect to everyone and everything. People always get the wrong idea of me and it gets so frustrating sometimes. I feel like I've become so closed off and I unintentionally bottle everything up. And when I bottle up my emotions, I never burst out angry or anything, I just don't do anything about it. It's made me become a very closed-off person and I swear something about my vibe sends people running/ makes them uncomfortable. lol. Bro, I thought I could do this on my own but Nah.
    `,
  },
  {
    title: `Outsider 🚶🏿‍♀️`,
    id: 4,
    name: `Lorraine Makuyana`,
    image: "/images/loo.jpeg",
    date: "Sep 22, 2020 2 min read",
    desc: `Chaos became my name. Of course, it was the confusion in my mind and the disaster I was facing but it was still chaos. Faith and hope are a thing of the past and mean nothing to me....`,
    content: `Chaos became my name. Of course, it was the confusion in my mind and the disaster I was facing but it was still chaos. Faith and hope are a thing of the past and mean nothing to me. I walk blindly, one foot in front of the other like a zombie, without a sense of direction or being. My mind and heart are loved by none, the tales told by my lonely heart and confused mind because of the heartbreaks I have had to endure and the friends who cut me off. Constantly, I tell myself it’s me against the world, but I don’t have the strength to face it and I need someone to carry me on their back since even my feet are not helping the cause. Save me. Save me from myself, from the loneliness, the hurt, the pain, and the tears that have now become a norm on my cheeks. My heart bleeds for the person I was once, carefree, and cheerful, sunshine to many. But that same person has evolved into this ugly and good-for-nothing nobody who is fighting so hard to survive even a day. By now, the scars on my heart and the stains of imperfections have left marks on my face I suppose, because one look at me and even you would want to look another way. I fail to recognize the person I see when I look in the mirror, the darkness has overshadowed me and placed me far beneath myself. I have been dealt with. 
    <br/>
    <br/>
    I lock myself in my little corner and cry daily, in the hope that maybe the heavens would have mercy on me. Or rather, I tell my best friend diary everything I am feeling, and she too doesn’t seem to respond with an answer, talk less of just a little comfort or hope. In my loneliness, I bundle myself up somewhere and sob quietly, until my head begins to ache as I cry my lungs and eyes out, knowing fully well that no one is coming to the rescue. I am now a stranger to myself, and sometimes I look in the mirror hoping to see just one streak of light but there is none. On the rare days I leave my room, I put on an aura of fake happiness and show love to all, smiling ever so brightly like everything is okay while deep inside I want the earth to open and swallow me whole. I have mastered the art of pretense. I won’t let anyone see or know what a mess I have become and what darkness I hold up inside. I fight my demons face to face every day, and sometimes I think even the angels instructed to take care of me are on their side because of the uncountable battles I have lost. I have become a slave to myself. In my next life, I hope I am taken to a far better place where I can breathe without difficulty and be someone.
    <br/>
    <br/>
    I survive each day that comes because I realized that one day at a time, my death day comes closer, and maybe then, I may find peace. I still feel the emptiness of my soul draw back my smile, and in the silence, I can hear my deep breaths try to calm me down. Buried deep in my soul is the urge to speak, smile, laugh, cry, and scream altogether but the emptiness questions my sanity. The rhythm of my heartbeat syncs with the seconds of the clock as I reminisce about the times of distress and depression, and I realize I need help. My bed has seized to offer me comfort, for, with every creak as I fidget, the piercing sound always triggers me. The void that sits inside me seems to explode there within, and as the anxiety and nervousness continue to drown me, the knife and pills on the table beside me seem to be the only way out. Sometimes, I wish I could let it all out and allow everyone to hear the screams, shouts, cries, and sobs that have become my everyday music coexisting inside me. Instead, I write about this, not only because of the peace it gives my mind and the weight it lifts off my shoulders, but at least here, the world can see the darkness behind the pretty face and beautiful smile that brightens the days of many. I draw all my energy and strength and take to my heels on this wide tar, hoping to outrun myself and leave behind the burdens of today. Unfortunately, they catch up with me still. Here we go again.
    `,
  },
  {
    title: `Reasons Why You Should Ever Consider Seeing A Therapist 🫂`,
    id: 5,
    name: `Trish Maduche`,
    image: "/images/therapy.jpeg",
    date: "Nov 5, 2022 2 min read",
    desc: `The word therapist is almost a taboo in African societies. Imagine telling people, “ Oh hey, I am going to see my therapist.” Most people would probably translate it to be, “Oh hey, I am running mad...`,
    content: `The word therapist is almost a taboo in African societies. Imagine telling people, “ Oh hey, I am going to see my therapist.” Most people would probably translate it to be, “Oh hey, I am running mad and am going to visit the psychiatrist.” We are raised in this twisted paradigm that mental health issues are non-existent, and are things we just imagine.
    <br/>
    <br/>
    I wish I had had access to a therapist when I was seventeen years old, at the time when I began to have mental health problems. At that time, I did not even know how to interpret the predicament I was in. I felt that no one would be able to understand me even if I opened up. Indeed, no one understood me, noticed any negative changes in me, or even asked me what was wrong…
    <br/>
    <br/>
    Had I had access to a therapist, I would have had someone to pour my heart to. Which brings me to reason number one on why you should consider seeing a therapist: You have someone to pour your heart to. We are living in a society where everyone is trying to make ends meet for themselves and, to be honest, they may not have time for us. Even if they have the time, they may fail to understand you. However, when you have a therapist, you have someone to tell all your worries.
    Reason number 2: No judgment. There are times you just want to shout, wail, or laugh as hard as you can without fear of judgment. I can tell you there are countless times I have had issues bothering me and have failed to tell even my friends because I am afraid that they will either laugh at me or judge me. However, when you have a therapist, you can feel free to tell them anything: from your anxieties, sorrows, and even about that person you have a huge crush on. 
    <br/>
    <br/>
     One of the biggest reasons I would recommend a therapist is: You are not vulnerable to the whole world. I was once (and can be sometimes) that person whom when I have something bothering me, I run to any person I can think of. Later, I drown in regret, “ Oh I wish I had not been that open, now they know my weakness, now I feel indebted to them. Now, this…Now that…” All this can be avoided if you have a therapist. You know that if you have any issue, you have that one person you are going to go to. That one person who will not talk about you with the next person, will not judge you or use your weakness against you, and best of all, will genuinely try to help you.
     <br/>
     <br/>
     I do highly recommend a therapist to everyone, whether you think you have mental health issues or not. The key is to get a therapist that you relate to and connect with. As young people, we need to normalize therapy, for our own good, and our own sanity. I wish I had gotten a therapist sooner…Do not let this be your story… Get one now.
    `,
  },
 
 

  {
    title: `Bad Jokes and Mental Health 🦧 `,
    name: `Francine Arthur`,
    image: "/images/lindiwe.png",
    id: 6,
    date: "Sep 22, 2020 2 min read",
    desc: `"It’s just a joke" seems nowadays to be a common afterthought or an escape route for many Africans after making a potentially hurtful comment...`,
    content: `“Ei, it was just a joke, nau?!” my friend exclaims, seeing my crestfallen face. 
    
    <br/>
    <br/>
    "It’s just a joke" seems nowadays to be a common afterthought or an escape route for many Africans after making a potentially hurtful comment. It is believed that the average African should be thick-skinned, a requirement for the relatively harsh conditions prevalent on the continent. But often, these statements cut to the core, and our inability to recognize them for the joke they are purported to be makes us feel even worse.
    <br/>
    <br/>
    I have had my fair share of bad jokes. Ranging from being “too serious to have fun with” to “she’d turn the party into a lesson”, I often felt my “friends” wanted to communicate something to me but felt scared to or thought it would be unkind and so would hastily cover up with the cliché “It’s just a joke”.
    <br/>
    <br/>
    This is not to say that one cannot tease or have fun with people simply because they may not be in the best of states mentally (mind you, mental health is not limited to the extreme cases of disorders that result in people acting in ways considered non-standard but also includes stress, anxiety and many more common issues). A good joke produces a hearty laugh that can go a long way to relieve tension and remind one that life is still good. A joke that attacks something a person does that one considers out of the norm, or is peculiar to them, on the other hand, could easily hit a soft spot and confirm a bad image a person has been trying to repair or struggling not to accept.
    <br/>
    <br/>
    “Well, that’s your problem, not my problem. How am I to blame if you can’t take a simple joke? And how else do you expect me to tell you you’re acting weird…again?”
    <br/>
    <br/>
    An honest well-meaning conversation can never be substantiated with an off-hand joke. I can still remember the many times someone called me an antisocial being or a nerd and hastily said it was only a joke. Even now, many years on and clearly not antisocial, it cuts. It makes me very insecure and even sometimes, can set me off for days. 
    <br/>
    <br/>
    Now, to people suffering from various degrees of mental health issues. Even when our mind tells us that these comments should not hurt, they do, deeply. But while we would love to, we cannot control and prevent every hurtful external comment. And so, we need to toughen up and brace ourselves for them. Several mechanisms have been suggested over the years to deal with jokes that are not so funny and hurt. Personally, this is what works well. Find (and know) who you are and constantly remind yourself of this daily. And then, sieve through every comment with the filter of self-knowledge and worth, discarding the hateful after picking out its usefulness. Because while the statement may be hurtful, there is often an element of poorly packaged truth in it, no matter how much we hate to admit it. Over the years, I have realized that I am not antisocial. Yes, I do prefer quieter environments and shy away from sustained rowdiness, but I interact pretty well with my not-so-small circle of friends. When I critically analyze the offhand jokes, however, I see some elements of truth. I tend to shun people and situations I find too noisy uncourteously. I also found it harder to interact with people outside of my circle or whose values and lifestyles differed slightly from mine. These were critical feedback that I did need to grow, but they were delivered to me in damaging packaging. Nevertheless, fair self-knowledge helped me to see through the dirt the gold nuggets in those comments.
    <br/>
    <br/>
    Jokes are fun. Jokes make conversation fun. But jokes that make fun of another’s weakness or seeming oddity are unhelpful and unneeded. Let’s dish out some loving honesty today.
    `,
  },
  {
    title: `I Choose My Mental Health 🧑🏽‍🎓`,
    name: `La Grace Divine Igirubuntu `,
    image: "/images/ichoosemymentalhealth.jpeg",
    id: 7,
    date: "Sep 22, 2020 2 min read",
    desc: `Cherish your mental health and take care of it. Don’t let studies or work get over your capacity. Take the time to relax, rest, visit nice places, friends, or families, clear your mind...`,
    content: `When we hear about mental health, we understand the key points: how we think, feel, and take things and behave in society. This is a big topic that affects so much in our lives; as it is said, a person is born perfect, but due to different factors, including biological and social factors, he/she can change day by day into someone who is either good or bad. For example, a child born in a modern society will grow modernized to the extent that at only five years, that child will be able to use a mobile phone very well or play electronic games as is seen these days; a child who was born in Africa in their early teenage life, was able to take an arrow and a bow to kill birds at only his five years, that is how much influence the societies we grow up in can have an influence on us. 
    <br/>
    <br/>
    Mental health is something in the African community we don’t care too much about. The more we ignore the existence of mental illness here in Africa, the more detrimental the effects of these illnesses are going to be.  It is then essential to take care of our mental health because mental illnesses do not just occur abruptly, but they occur step by step and we have to try to notice these symptoms in the people we love early enough. 
    <br/>
    <br/>
    In many schools, it is frequently said that students studying sciences, especially those who are regarded as “geniuses”, end up suffering from mental illnesses.  I remember when I was studying in my ordinary-level secondary school, there was a student studying in senior six and he was considered a genius. He was prompt, helped others with their studies, was always in the labs, and was always the last to go to bed. He began to exhibit symptoms of someone with OCD but people ignored those symptoms since he was a genius so anything he did was normal for genius people. 
    <br/>
    <br/>
    His condition worsened during the final national exams. An hour into the final Math exam, he fainted and was taken to a nearby hospital. After a few days, we were informed that he got admitted to a mental health institution and couldn't continue with his exams. His friends often said that those days before, he was acting unusual, talking to himself, and having headaches, but they thought he was memorizing what was in his mind. 
    <br/>
    <br/>
    In everyday life, we are always with these people, but we can’t bring into our minds that they are acting in an unusual manner and need they probably need our help. We try to extend the distance between them and ourselves and never try to bring up a conversation. Approach, talk to, and try to change their minds is the best way to save them; if it is hard, convince them to see a therapist.
    <br/>
    <br/>
    We also need to take a step back and take good care of our own selves. It is good to work hard or study hard. That way, you can get good results and achieve big things in your life but remember that everything that costs your mental health is too expensive. You will not enjoy your outcome when you are in a hospital or worse still, even dead. Cherish your mental health and take care of it. Don’t let studies or work get over your capacity.  Take the time to relax, rest, visit nice places, friends, or families, clear your mind when you are tired and when you are not feeling well, and visit a therapist. Your mental health is as important as your physical health. 
    
    `,
  },
  {
    title: `Perfection is Overated   🐼`,
    name: `Lucia Chebi `,
    image: "/images/lucia.jpeg",
    id: 8,
    date: "Sep 22, 2020 2 min read",
    desc: `I had fallen into the comparison trap. A merciless and torturous trap that won't allow you to see how much worth you carry. "Perfect" people became my standard for measuring my worth. ...`,
    content: `When we hear about mental health, we understand the key points: how we think, feel, and take things and behave in society. This is a big topic that affects so much in our lives; as it is said, a person is born perfect, but due to different factors, including biological and social factors, he/she can change day by day into someone who is either good or bad. For example, a child born in a modern society will grow modernized to the extent that at only five years, that child will be able to use a mobile phone very well or play electronic games as is seen these days; a child who was born in Africa in their early teenage life, was able to take an arrow and a bow to kill birds at only his five years, that is how much influence the societies we grow up in can have an influence on us. 
    <br/>
    <br/>
    Once upon a time, I felt dead than a corpse could ever be. I became so empty and worthless that the only time I felt I mattered was when I was asleep. While the night drained my fears and worries with its sweet calmness and left me hopeful for the morning, the day choked me with endless disappointments and defeats. In a fight to become successful, I forgot what success really meant. In a fight to be happy, I forgot how to smile. In a fight to fit in, I lost myself. In a fight to be loved, I forgot to love myself. In my every fight to be like them, I forgot how it was to be me. Hence, I died without even being aware I was no more.
    <br/>
    <br/>
    Once upon a time, I was more than my pains and failures. More than my obsession to be like them. I was too mighty to be overpowered by them. Not that they did not affect me, but they just couldn't stop me from moving. 
    <br/>
    <br/>
    My nightmare began when I chose perfection over progress. I remember how my mom would compare my report card to my cousin's and tell me, "Can't you be like her." Not that my report card (14 average) was bad, but she always expected me to be the best. For her, the best meant being the top of my class. So, I felt I needed to be like my cousin or my "perfect" classmates to be accepted. Hence, a pinch of hatred for myself grew every time I failed or was average and couldn't be the best.
    <br/>
    <br/>
    I had fallen into the comparison trap. A merciless and torturous trap that won't allow you to see how much worth you carry. "Perfect" people became my standard for measuring my worth. At this pace, I was so worthless than rubbish, and that pinch of hatred for myself became a mountain. A mountain that stood between me and my real me. I had spent almost all my teenage years trying to be like others and forgot to discover who I really was. Trying to fit in and do things their way blinded me and made me unhappy. It created a void as big as the universe in my heart and soul. A void of a lost identity. Hence, I killed myself with my ignorance.
    <br/>
    <br/>
    As the writer of this story- my story- I couldn't allow my character to die when the pen was still in my hand and the pages of my book as empty as space. It is foolish to kill yourself when the pen is still in your palms. I learned that from one of my "perfect" classmates indirectly. He was always the top of the class in my secondary and high school. I admired and wished I could be like him. However, he made me dream of being myself the day I discovered that he considered me a role model. I wasn't flattered; I was rather confused. I struggled to fathom how a mess like myself could be a role model to a "perfect" person. Guess that was the beginning of my resurrection. 
    <br/>
    <br/>
    I had no right to fail myself in this new life; hence, I chose progress over perfection. Looking back at the days when I was the fake version of others, in my every fight to be perfect, there was progress that I was too blind to see. In the failures and mistakes that made me feel worthless, there was a thing about me to appreciate, progress. So, I leaned on that. I made it a point to appreciate my efforts, whether I failed or succeeded. I was on a journey of discovering, accepting, and loving my true self. This made me happy and hopeful. 
    <br/>
    <br/>
    You are the writer of your story. Be careful not to kill yourself because you deserve to be happy.
    "Be very careful about what you think. Your thoughts run your life." Proverbs 4:23


    `,
  },
  {
    title: `Depression  👤`,
    name: `Audrey Obuobisa-Darko`,
    image: "/images/audrey.jpeg",
    id: 9,
    date: "Sep 22, 2020 2 min read",
    desc: `Pain consumes me like a fire, eating away at my brain, at my heart, my body, beyond my body. I curse my very existence, which I do not have the courage to end, for I know well it never does....`,
    content: `
    It’s happening again, the eon in this human experience full of unfathomable, senseless episodes where my spirit and body no longer dance in harmony. I can taste on the tip of my senses that it is happening, because my body, this earthly home, begins to fall asunder, starting from this brain, the control center, the proscenium upon which all that concerns this physical body begins. There’s a fog in my brain, that’s the first sign. A tangible mass of shit that should not be there, like cotton. It feels like cotton. There’s nothing I can do to remove it. It’s not an itch I can scratch, not a thing on my skin I can brush off, not a wound on my knee I can treat. It’s far beyond the reach of my hands. I cannot reach in and take it out. 
    <br/>
    <br/>
    The cloud sits in my head and engulfs my brain. It’s like a mass of junk stuffed into my free headspaces, leaving no space for anything else. I cannot think. There’s no space for my thoughts to move. They panic, frantic, stir up a ruckus, gasping for air, protesting against the fog. My head is full of cotton, heavy. 
    <br/>
    <br/>
    Nothing makes sense that once made sense to me. I re-read messages from a conversation I was in before this started, but it doesn’t make sense. My friend, she’s still typing. A new message appears. It’s too long. I can tell she is talking about something simple, something I know of and can understand. But I don’t understand it. I don’t understand the words she’s saying. All this, happens within a matter of minutes. But the concept of time, it founders in these seas.
    <br/>
    <br/>
    I swipe the chat away and open another app, where I have begun to share a lot of my musings more unhinged than it is in my nature to. I feel immediately removed from it. Everyone is talking, angry, and arguing about this and that. And these this’s and thats are immediately foreign to me, distant. I am aware I know of these things, understand them, these ideologies, constructs, systems, things we talk about and live by on this earth. These things we have created to make the human experience more meaningful to us, lest we lose our minds. I watch on as everybody speaks about these this’s and that’s, and disagree on these made-up things, and get riled up, and angry, and frustrated, and pained, at these made-up things. And I wonder why. I know I know why, in some distant way, but I wonder why it’s happening. None of it seems real, these things.
    <br/>
    <br/>
    “We’ve made up all this shit to give life some meaning, but it is this same shit that comes back to hurt us,” I post. I’m overwhelmed by the perplexity of it all as I scroll, and scroll. The fog sits thicker, thicker, wider, bigger, thicker until there’s no space for me. I want to break free. From where? Through where? To where?  I lay down and breathe deeply to clear it. I read it helps. But it doesn’t really. Less, and less, and less space for me… and I slip. Not only removed from the things I read which I know I understand but do not understand, but removed from the body I once understood as an enclosed unlivable thing. 
    <br/>
    <br/>
    I realise I am not my body, because I leave it. It dawns on me that I am not what I thought I was. The fog, it pushed me out. I observe it all from a distance, not very far off. Far enough that I know I am unhinged from my vessel, but close enough to know I am still associated with it in some form. I observe my body, I observe the online spaces and all the unnatural, made-up things riddling it. 
    <br/>
    <br/>
    “Oh, you dissociate?”
    <br/>
    <br/>
    “No, I don’t think so. I don’t know what it is like to do that.”
    <br/>
    <br/>
    I describe it to her, my new friend. I made a new friend online, whom I had an instant connection with. We speak deeply about everything like we have known each other for years. I describe these moments where I writhe on my bed, uncomfortable within my body. I talk about the spaciness I feel, like what I think an acid trip would feel like, although I have never had an acid trip before. I tell her how I don’t feel ‘there’ anymore, that everything I once knew falls apart into its atomic forms, and I question the things I know I know because I don’t know them anymore in that instance although I know I know them. 
    <br/>
    <br/>
    She listens without judgment. This is why I like her. She says casually, “Yes, that’s what it means to dissociate.”
    <br/>
    <br/>
    But I still don’t think so. Because my idea of what it means to dissociate is nearly different. Although I do not have a name for these experiences I have been having, either. My idea of dissociating is of the people with the many people within them. One leaves, one takes over. But me, I leave, and just leave, but not completely. Nothing takes over, at least as far as I can tell. This new information scares me, the possibility of it, because it is not what I know. It is not what I know of myself, which is already too heavy for me to carry, too complex for me to understand, control. It scares me for a long time, what she said.
    <br/>
    <br/>
    My mother, she knows there’s something wrong. I can tell by the way she looks at me, the despair in her eyes. There’s a wall between me and her, invisible, yet made of a thousand solid pains. She sits on my bed and watches me. Her voice is loving and comforting. “Please, show me what to do.” It breaks, the wall, blown to smithereens, Jericho. Tears fall down my face and past the broken dam. They flow to the other side, where she’s stood for years, waiting. She holds me in her arms as I cry, and cry, and cry. Love flows from her body and into mine, in a language that transcends mere talking. She clears my table of the clutter drowning it, days and days of rubbish, and decayed food. “When I come back, I’ll remove all these clothes from your bed.”
    <br/>
    <br/>
    “Love is healing,” I share online. And I know I do not mean in it any performative or woo-woo or kumbaya way, because I literally feel within my body that I am better than the time before she walked in, when I wailed, and cursed, and clawed at the minuscule store of love within my spirit. People are moved, and they comment, happy with this mother who holds her adult child in her arms as she cries, who cleans her adult child’s room, who recognizes her adult child’s pain. They send me their love, their comfort, their kind words, their money, and their love. “Get your head up immediately.” “Let me buy you lunch.” “What does support look like to you?” “I love you.”  
    <br/>
    <br/>
    I marvel at the power of community. It gives me strength for the morning, for I bathe with soap and a sponge today. I go outside for the first time in a long time. My clothes are not clean. I find a garden, and I bask in it in a joy I have never once felt before. My friend meets me. He listens to me ramble, watches me cry, and listens to me ramble again. I know I say a lot of strange shit, but he never flinches. This is why I like him. We sit under the trees, mindless of the time that passes us by. We eat a lot of comfort food. He takes a picture of me while I laugh and shows it to me. I do not feel associated with the face in the picture, its person. The mirth fades from my eyes as he leaves. 
    <br/>
    <br/>
    When the darkness of night comes, my lover asks me, “would you like me to make you a sandwich?” We sit in loud silence, looking, but not speaking. The air between us sits heavy, a mixture of anger, and neglect, and anguish, and love, and love, and love. When I get home, I move aside the pile of clothes on my bed to make space for my body, and sleep.
    <br/>
    <br/>
    I feel good when I wake up the next morning. I record it in my journal. 12:02 p.m. Trying to do some work! I open my laptop. 12:17 p.m. Feeling back-seated again. Feels like I’m slipping farther back away. Far, far, away. I close my laptop. 12:something p.m. Crying, fighting it. I was okay minutes ago. 
    <br/>
    <br/>
    2:something pm. I’m having such a hard time.
    <br/>
    <br/>
    I eat something infused with God’s plant, and I observe myself ascend into a different state of consciousness, of being. My senses open up before me, acutely sensitive to all that’s been there but never noticed, discerned. Music courses through my body, its waves of sound one with the wave of me. The taste of food is heavenly ambrosia on my tongue.
    <br/>
    <br/>
    I feel grounded, in control, for the first time since this eon began. I can channel my energy onto what I want. I watch, and listen to people on my screen talk about the most marvelous things in the universe. They talk about magic, and kindness, and spirituality, and creativity, and sex, and God, and being human, and consciousness, and love, and love, and love. Every experience they share, it reaches me in the same wavelength they once felt it. I am one with every piece of art I see. I observe my ego fall away, and everything I once knew as a part of my self, with it. I smile, and laugh, for the first time since this eon began.
    <br/>
    <br/>
    The next morning, I’m back from my ascent, plunged back into my reality, deeper in its throes. I write in my journal: feels like my brain is rotting. My internet friend sends me a message, the one who understands me. She talks about emotions, and the suppressing of them. I talk about emotions, and the expressing of them. I tell her about the episodes, the searing pain in my chest, the weight of the world on my shoulders, the meaninglessness of existence on my mind. I ruminate on the harrowing order of life, how it could have been engineered in an entirely different way, the evils of capitalism, and colonialism, and oppression, and religion, and discrimination, and the awful lack of love, of love, of love. I tell her about how it starts with a minute sad feeling, and then spirals into a thousand heavy cares. She understands.
    <br/>
    <br/>
    Pain consumes me like a fire, eating away at my brain, at my heart, my body, beyond my body. I curse my very existence, which I do not have the courage to end, for I know well it never does. My body is too small a place for my being, too weak a vessel for its ways. It withers, it breaks, it breaks. My mother sees me, my hands on my head, my body on the kitchen floor. She holds me, she cries, I cry, as love flows from her body into mine, piecing me together. It gives me strength for the evening. It gives me strength to be.

    `,
  },


];

export default reasearchData;
