
import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { AppImage } from "@/components/AppImage";
import { LinkButton } from "@/components/LinkButton";
import { Routes } from "@/lib";

function BlogItem({ item }) {
  
  return (
    
    <Box py={"35px"}>
       <br/>
        <br/> 
                
      <FadeInAnimation>
        <Flex

          bg={"#f5f5f5"}
          flexDirection={["column", null, "row"]}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <AppImage src={item.image} h={"400px"} w={"50%"} flexGrow={"0"} />
          <Box pl={"50px"} maxW={"550px"}>
            <Text
              fontFamily={"heading"}
              fontWeight={"500"}
              my={"22px"}
              fontSize={"2xl"}
            >
              {item.title}
            </Text>
            <Text
              fontFamily={"subheading"}
              fontWeight={"50"}
              my={"22px"}
              fontSize={"1xl"}
            >
              Author: {item.name}
            </Text>
            <Text fontFamily={"body"} fontWeight={"500"} my={"20px"}>
              {item.desc}
            </Text>
            <LinkButton
              href={`${Routes.research}/${item.id}`}
              label={"Read More"}
            />
          </Box>
        </Flex>
      </FadeInAnimation>
    </Box>
  );
}

export default BlogItem;
